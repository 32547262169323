import React from "react";

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        fill="#FEEEEB"
        stroke="#F55739"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 34.833c8.745 0 15.833-7.088 15.833-15.833 0-8.744-7.088-15.833-15.833-15.833-8.744 0-15.833 7.089-15.833 15.833 0 8.745 7.089 15.833 15.833 15.833z"
      ></path>
      <path
        stroke="#F55739"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 12.667v12.666M12.667 19h12.666"
      ></path>
    </svg>
  );
}

export default AddIcon;