import React from 'react';
// material-ui components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// atoms components
import Button from '@atoms/Button';
import InputField from '@atoms/InputField'; 
import CircularProgress from '@material-ui/core/CircularProgress';
// custom app icon
import AddIcon from '@icons/AddIcon'; 
// app utils
import firebase from "gatsby-plugin-firebase"



/** 
 * @component SelectField 
 * 
 * @props id    - input id
 * @props label -input label
 * @props field - control input react-hook-form
 * @props withIcon - bolean
 * @props options  - option array{ value: "",label: "",icon: "icon-url"}
 * */ 
export default function UploadField(props) {

// current image upload progress
const [ progress, setProgress ] = React.useState(false)
// current image
const [ image, setImage ] = React.useState()
// create Ref
const fileRef = React.useRef(null);

// when the Button component is clicked
const handleClick = event => {
  fileRef.current.click();
};


// upload image to firebase storage auto
 function handleSubmit(event) {
    event.preventDefault();
     
    setProgress(true)

    const file = fileRef.current.files[0];

    let uploadTask = firebase.storage().ref().child(file.name).put(file);


    uploadTask.on(
      "state_changed",
      snapshot => {
        // Handle progress of upload
        let progress = Math.round(
          snapshot.bytesTransferred / snapshot.totalBytes * 100
        );
      },


      error => {
        // Handle unsuccessful upload

      },


      () => {

        // Handle successful upload

        uploadTask.snapshot.ref.getDownloadURL()
        .then(downloadURL => {
          setProgress(false)


          props.setValue(props.field.name, downloadURL)

          setImage(downloadURL)

        })

      }
    );
  }



return (
<Box>

    <Box
      style={{
        width: 254,
        height: 231,
        borderRadius: 5
      }}
    >
          { !image && (
            <>
              <Button 
                onClick={handleClick}
                style={{
                  width: '100%',
                  height: '100%',
                  background: 'rgba(245, 87, 57, 0.1)',
                  border: '1px dashed #F55739',
                  borderRadius: 5
                }}
                disabled={progress}
              >
                 {progress ? <CircularProgress color="secondary"/> :  <AddIcon /> }
              </Button>

              <input
                type="file"
                accept="image/*"
                multiple={false}      
                onChange={handleSubmit}
                ref={fileRef}
                style={{display: 'none'}}
             />  

            </>
        )}

        { image && (
           <Box style={{ 
             height: "100%", 
             width: "100%",
             backgroundSize: 'cover',
             backgroundPosition: 'center',
             backgroundRepeat: 'no-repeat',
             backgroundImage: `url(${image})`
           }}>  
           </Box>  
        )}
    </Box>

    {/* input title */}
    <Typography 
      variant="h6"
      style={{
        fontWeight: 'bold',
        fontSize: 15,
        lineHeight: '18px',
      }}
     >
        {props.title}
    </Typography>

    {/* input subTitle */}
    <Typography 
      variant="subtitle2" 
      style={{ 
        fontSize: 15,
        lineHeight: '18px',
        color: '#797979'
      }}
    >
        {props.subTitle}
    </Typography>


    {/* hidden  TextField to handle image form */}
    <Box style={{display: 'none'}}>
         <InputField
            {...props.field}
         />
    </Box>
    <p style={{color: 'red', margin: 0}}>{props.error && "رجاء قم برفع الصورة*"}</p>

</Box>
  );
}
