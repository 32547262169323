import React from "react"
//
import Layout from "@utils/layout"
import Seo from "@utils/seo"
//
import Box from '@material-ui/core/Box';
//
import Breadcrumb from '@atoms/Breadcrumb';
//
import SectionFromHeader from '@molecules/Sections/SectionFromHeader';
import SellForm from '@molecules/Form/SellForm';


export default  function CarSearchPage(props) { 


  return (
    <Layout>
      <Seo title="بيع سياراتك" />

     <Box>


      {/*  Breadcrumbs */}
      <Box>
        <Breadcrumb
           page="بيع سياراتك"
        />
      </Box>

      {/* Form Header Section */}
      <SectionFromHeader />


      {/*  form inputs body*/}
      <Box>

        {/* form */}
        <SellForm />

      </Box>

  </Box>



    </Layout>
  )
}


